import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config.js'
const version = require('./version.json');
// import { TailwindConfigDefault } from 'tailwindcss/tailwind-config-default'
// import { TailwindConfig, TailwindThemeColors } from 'tailwindcss/tailwind-config'
// const baseURL: string = (
//   process.env.VUE_APP_ENABLE_MOCK == "DISABLED"
//     ? (process.env.VUE_APP_ZEBRIT_BACKEND_URL as string)
//     : "/"
// ) as string; // needs trailing slash
const baseURL: string = process.env.VUE_APP_BASE_URL as string; // needs trailing slash
const baseBackendURL: string = process.env.VUE_APP_ZEBRIT_BACKEND_URL as string; // needs trailing slash
//const baseApiURL: string = `${baseURL}api/` as string; // needs trailing slash
const baseApiURL: string = (
  process.env.VUE_APP_ENABLE_MOCK === "ENABLED"
    ? `${baseBackendURL}api/`
    : `${baseBackendURL}api/`
) as string; // needs trailing slash
const webSocketURL: string = process.env.VUE_APP_WEB_SOCKET_URL as string; // no trailing slash



const fullConfig = resolveConfig(tailwindConfig) as any
const colors = fullConfig?.theme?.colors;
// console.log(fullConfig.theme.colors);
// debugger; // eslint-disable-line
/**
 * Adding a property to this object will create a repository for that property.
 * Ex: user: { alias: 'user' } will create $visitorRepository accessible in each component's "this".
 * */
export const resources = {
  user: {
    alias: 'user',
    associations: {
      groups: {
        alias: 'group'
      },
      permissions: {
        alias: 'permission'
      },
      connections: {
        alias: 'connection'
      },
      conversations: {
        alias: 'conversation'
      },
      notifications: {
        alias: 'notification'
      },
      documents: {
        alias: 'document'
      },
      sharedDocuments: {
        alias: 'shared-document'
      },
      images: {
        alias: 'image'
      }
    }
  },
  conversation: {
    alias: 'conversation'
  },
  notification: {
    alias: 'notification'
  },
  connection: {
    alias: 'connection'
  },
  auditLog: {
    alias: 'auditLog',
    options: {
      filterDeleted: false
    }
  },
  document: {
    alias: 'document',
    associations: {
      users: {
        alias: 'user'
      }
    }
  },
  image: {
    alias: 'image'
  },
  visitor: {
    alias: 'visitor'
  },
  role: {
    alias: 'role',
    associations: {
      users: {
        alias: 'user'
      },
      permissions: {
        alias: 'permission'
      }
    }
  },
  group: {
    alias: 'group',
    associations: {
      users: {
        alias: 'user'
      },
      permissions: {
        alias: 'permission'
      }
    }
  },
  permission: {
    alias: 'permission',
    associations: {
      users: {
        alias: 'user'
      },
      roles: {
        alias: 'role'
      },
      groups: {
        alias: 'group'
      }
    }
  }
}


const Config = {
  baseURL: baseURL,
  baseFileUrl: baseBackendURL,
  baseApiURL: baseApiURL,
  WebSocketUrl: webSocketURL,
  websocketURI: webSocketURL,
  version: version,
  API: {
    USER: resources?.user?.alias || 'user',
    PERMISSION: resources?.permission?.alias || 'permission'
  },
  stripe: {
    pricingTableID: process.env.VUE_APP_STRIPTE_PRICING_TABLE_ID,
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
  },
  api: {
    account: {
      login: `${baseApiURL}auth/login`,
      logout: `${baseApiURL}auth/logout`,
      // user: `${baseApiURL}auth/currentuser?$embed=role&$embed=organisation`,
      currentuser: `${baseApiURL}auth/currentuser`,
      user: `${baseApiURL}auth/user`,
      // userOrganisations: `${baseApiURL}user/{ownerId}/organisation`,
      // registrationStep1: `${baseApiURL}auth/registration/step1`,
      // registrationStep2: `${baseApiURL}auth/registration/step2`,
      // registrationStep3: `${baseApiURL}auth/registration/step3`,
      registrationStep1: `${baseApiURL}auth/register`,
      registrationStep2: `${baseApiURL}auth/register/activate`,
      registrationStep3: `${baseApiURL}auth/register/organisation`,
      // registrationStep2: `${baseApiURL}auth/register/send-activation-email`,
      // registrationStep3: `${baseApiURL}auth/register/activate'`,
      registrationCreateOrganisation: `${baseApiURL}auth/registration/create-Organisation`,
      verify: `${baseApiURL}auth/verify`,
      forgotPass: `${baseApiURL}auth/login/forgot`,
      resetPass: `${baseApiURL}auth/login/reset`
    },
    assets: {
      assets: `${baseApiURL}asset`,
      assetsGraph: `${baseApiURL}asset/graph`,
      assetsCategories: `${baseApiURL}asset-category`,
      // assetsCategories: `${baseApiURL}asset-category/tree`,
      assetsCategory: `${baseApiURL}asset-category`,
      assetsCategoryTree: `${baseApiURL}asset-category/tree`,
      assetsSubcategory: `${baseApiURL}asset-category/{id}/asset-category`,
      assetsManufacturers: `${baseApiURL}manufacturer`,
      assetsManufacturerModels: `${baseApiURL}model`,
      // mine: `${baseApiURL}asset-mine`,
      mine: `${baseApiURL}asset/mine`,
      lifecyclestage: `${baseApiURL}asset/lifecyclestage`,

      assetsFields: `${baseApiURL}asset-field?$embed=category&$sort=assetFieldId`,
      assetsField: `${baseApiURL}asset-field/{id}?$embed=category`,
      assetsFieldCategories: `${baseApiURL}asset-field/{id}/assetCategory`,

      assetImportDoImport: `${baseBackendURL}asset-import/do-import`,

      assetExportGenerate: `${baseBackendURL}asset/export`,
      assetExportList: `${baseBackendURL}asset/exports`,
      assetExportRemove: `${baseBackendURL}asset/export/{file}`,
      assetExportDownload: `${baseBackendURL}asset/export/{file}`,
    },
    asset: {
      // get: `${baseApiURL}asset/{id}?$embed=documents&$embed=pictures`,
      get: `${baseApiURL}asset/{id}?$embed=documents`,
      create: `${baseApiURL}asset`, // post
      update: `${baseApiURL}asset/{id}`, // put
      delete: `${baseApiURL}asset/{id}`, // delete
      chart: {
        hasAny: `${baseApiURL}asset/chart/has-any`,
        categories: `${baseApiURL}asset/chart/categories`,
      }
    },
    search: {
      searchAssets: `${baseApiURL}search/assets`,
    },
    ticket: {
      chart: {
        types: `${baseApiURL}ticket/chart/types`,
        severities: `${baseApiURL}ticket/chart/severities`,
        statuses: `${baseApiURL}ticket/chart/statuses`,
        bymonth: `${baseApiURL}ticket/chart/bymonth`,
      },
      // get: `${baseApiURL}ticket/{id}?$embed=ticketActivity`,
      get: `${baseApiURL}ticket/{id}`,
      // ticketsGraph: `${baseApiURL}ticket/graph`,
      ticketsCategories: `${baseApiURL}ticket/categories`,
      ticketsCategory: `${baseApiURL}ticket/category`,
      getActivities: `${baseApiURL}ticket/{id}/ticket-activity?$embed=user&$sort=-createdAt`,
      getActivityTypes: `${baseApiURL}ticket/ticket-activity-types`,
      saveActivity: `${baseApiURL}ticket-activity`,
      assignActivity: `${baseApiURL}ticket/{id}/ticket-activity`,
    },
    tickets: {
      tickets: `${baseApiURL}ticket`,
      ticket: `${baseApiURL}ticket`,
      ticketAssign: `${baseApiURL}ticket/assign`,
      ticketUnassign: `${baseApiURL}ticket/unassign`,
      ticketAccept: `${baseApiURL}ticket/accept`,
      ticketReject: `${baseApiURL}ticket/reject`,
      // mine: `${baseApiURL}my-ticket`,
      mine: `${baseApiURL}ticket/mine`,
      // ticketGraph: `${baseApiURL}ticket/graph`,
      ticketTypes: `${baseApiURL}ticket/types`,
      ticketSeverities: `${baseApiURL}ticket/severities`,
      ticketStatuses: `${baseApiURL}ticket/statuses`,
      ticketStatusOrder: `${baseApiURL}ticket/status-reorder`,
    },
    staffings: {
      staffings: `${baseApiURL}staffing`,
      staffing: `${baseApiURL}staffing`,
      // ticketAssign: `${baseApiURL}ticket/assign`,
      // ticketUnassign: `${baseApiURL}ticket/unassign`,
      // ticketAccept: `${baseApiURL}ticket/accept`,
      // ticketReject: `${baseApiURL}ticket/reject`,
      staffingStatuses: `${baseApiURL}staffing-status`,
      staffingStatusOrder: `${baseApiURL}staffing/status-reorder`,
    },
    staffing: {
      get: `${baseApiURL}staffing/{id}`,
      checkList: `${baseApiURL}staffing/{id}/staffingChecklist?$embed=serviceType,assignee`,
      checkListCreate: `${baseApiURL}staffing-checklist`,
      checkListUpdate: `${baseApiURL}staffing-checklist/{id}`,
      checkListDelete: `${baseApiURL}staffing-checklist/{id}`,
      deleteItem: `${baseApiURL}staffing/{id}`, // delete
    },
    knowledges: {
      knowledges: `${baseApiURL}article`,
      knowledge: `${baseApiURL}article`,
      knowledgesCategories: `${baseApiURL}article-category`,
      knowledgesCategory: `${baseApiURL}article-category`,
    },
    knowledge: {
      get: `${baseApiURL}article/s/{slug}`,
      getByID: `${baseApiURL}article/{id}`,
      create: `${baseApiURL}article/new`,
      update: `${baseApiURL}article/{id}`,
      delete: `${baseApiURL}article/{id}`,
    },
    chatbot: {
      messages: `${baseApiURL}chatbot/messages`,
      send: `${baseApiURL}chatbot/send`,
      settings: `${baseApiURL}chatbot/settings`,
    },
    notification: {
      messages: `${baseApiURL}notifications`,
      seen: `${baseApiURL}notifications/seen`,
    },
    organisation: {
      organisation: `${baseApiURL}organisation`,
      users: `${baseApiURL}user?$embed=role&$embed=organisation`,
      user: `${baseApiURL}user`,
      groups: `${baseApiURL}group`,
      group: `${baseApiURL}group`,
      roles: `${baseApiURL}role?$embed=permissions`,
      role: `${baseApiURL}role`,
      permissions: `${baseApiURL}permission`,
      testsupportemail: `${baseApiURL}organisation-test-support-email`,
      savesupportemail: `${baseApiURL}organisation-save-support-email`
    },
    user: {
      users: `${baseApiURL}users`,
      user: `${baseApiURL}user`,
      invite: `${baseApiURL}invite`,
    },
    tag: {
      tags: `${baseApiURL}tag`,
    },
    payment: {
      customer: `${baseApiURL}stripe-customer`,
      invoice: `${baseApiURL}stripe-invoice`,
      paymentMethod: `${baseApiURL}stripe-payment-method`,
      subscription: `${baseApiURL}stripe-subscription`
    },
    stripe: {
      plan: `${baseApiURL}stripe-get-plan-by-key/{id}`
    },
    serviceCatalogue: {
      item: `${baseApiURL}service-catalogue-item`,
      category: `${baseApiURL}service-catalogue-category`,
      priority: `${baseApiURL}service-catalogue-priority`,

      getItem: `${baseApiURL}service-catalogue-item/{id}`,
      createItem: `${baseApiURL}service-catalogue-item`, // post
      updateItem: `${baseApiURL}service-catalogue-item/{id}`, // put
      deleteItem: `${baseApiURL}service-catalogue-item/{id}`, // delete
    },
  },
  file: {
    assetFileUploadDocument: `${baseBackendURL}upload/asset-document`,
    assetFileDownloadDocument: `${baseBackendURL}`,

    assetFileUploadImportCSV: `${baseBackendURL}upload/asset-import`,

    assetExportCSVPath: `${baseBackendURL}asset/export`,

    avatarUpload: `${baseBackendURL}upload/user-avatar`,
    avatar: `${baseBackendURL}`,

    assetPictureUpload: `${baseBackendURL}upload/asset-picture`,
    assetPicture: `${baseBackendURL}`,

    organisationLogoUpload: `${baseBackendURL}upload/organisation-logo`,
    organisationLogo: `${baseBackendURL}`,

    assetCategoryIconUpload: `${baseBackendURL}upload/asset-category-icon`,
    assetCategoryIcon: `${baseBackendURL}`,

    fileUpload: `${baseBackendURL}file/upload/fileupload`,
    fileDownload: `${baseBackendURL}`,
  },
  chartDefaultFont: 'Inter var experimental, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  //https://www.learnui.design/tools/data-color-picker.html#palette
  chartBarBorderRadius: 5,
  chartDefaultOptions: {
    dark: {
      // backgroundColor: colors.black['800'],
      backgroundColor: colors.red['500'],
      borderColor: 'transparent',
      color: colors.gray['200'],
      gridColor: colors.gray['700'],
      seriesColor: [
        '#0074aa',
        '#00a0c0',
        '#00c8af',
        '#80e885',
        '#fffb65',
        // '#0074aa',
        // '#008dbb',
        // '#00a6c0',
        // '#00bdb7',
        // '#00d2a4',
        // '#6ce48c',
        // '#b7f273',
        // '#fffb65'
      ],
      seriesStackedColor: [
        // '#0074aa',
        // '#00a0c0',
        // '#00c8af',
        // '#80e885',
        // '#fffb65',
        '#0074aa',
        '#00aebe',
        '#50de94',
        '#fffb65',
      ]
    },
    light: {
      // backgroundColor: colors.black['200'],
      backgroundColor: colors.blue['500'],
      borderColor: 'transparent',
      color: colors.gray['800'],
      gridColor: colors.gray['200'],
      seriesColor: [
        '#003f5c',
        '#58508d',
        '#bc5090',
        '#ff6361',
        '#ffa600'
        // '#003f5c',
        // '#2f4b7c',
        // '#665191',
        // '#a05195',
        // '#d45087',
        // '#f95d6a',
        // '#ff7c43',
        // '#ffa600'
      ],
      seriesStackedColor: [
        // '#003f5c',
        // '#58508d',
        // '#bc5090',
        // '#ff6361',
        // '#ffa600'
        '#003f5c',
        '#7a5195',
        '#ef5675',
        '#ffa600'
      ]
    }
  },
  getChartDefaultOptions() {
    // let colors = fullConfig?.theme?.colors;
    // concolors;
    // debugger; // eslint-disable-line
    return JSON.parse(JSON.stringify(this.chartDefaultOptions));
  },
  // chartDefaultOptions: {
  //   ref: "https://echarts.apache.org/en/option.html",
  //   title: {
  //     text: "Chart Title",
  //     left: "left",
  //   },
  //   showLoading: true,
  //   backgroundColor: "rgba(0,0,0,0)",
  //   tooltip: {
  //     trigger: "item",
  //     //formatter: "{a} <br/>{b} : {c} ({d}%)",
  //     formatter: "{b}: {c}<br/>[{d}%]",
  //     confine: true,
  //     textStyle: {
  //       fontSize: 12,
  //     },
  //   },
  //   legend: {
  //     orient: "vertical",
  //     left: "right",
  //     data: [],
  //   },
  // },
  // chartBarDefaultOptions: {
  //   ref: "https://echarts.apache.org/en/option.html",
  //   title: {
  //     text: "Chart Title",
  //     left: "left",
  //   },
  //   backgroundColor: "rgba(0,0,0,0)",
  //   tooltip: {
  //     trigger: "axis",
  //     axisPointer: {
  //       type: "shadow",
  //     },
  //   },
  //   legend: {
  //     top: "15%",
  //   },
  //   grid: {
  //     left: "3%",
  //     right: "4%",
  //     bottom: "3%",
  //     containLabel: true,
  //   },
  //   // xAxis: {
  //   //     data: ['Oct', 'Nov', 'Dec', 'Jan', 'Feb']
  //   // },
  //   xAxis: {},
  //   yAxis: {
  //     type: "value",
  //   },
  //   series: [],
  // },
  // chartPieSeriesDefaultOptions: {
  //   type: "pie",
  //   radius: "50%",
  //   center: ["50%", "60%"],
  //   emphasis: {
  //     // showSpinner: true,
  //     itemStyle: {
  //       shadowBlur: 10,
  //       shadowOffsetX: 0,
  //       shadowColor: "rgba(0, 0, 0, 0.5)",
  //     },
  //   },
  // },
  // chartBarSeriesDefaultOptions: {
  //   type: "bar",
  //   stack: "total",
  //   // showSpinner: true,
  //   emphasis: {
  //     focus: "series",
  //   },
  // },
  // getChartDefaultOptions: function () {
  //   return JSON.parse(JSON.stringify(this.chartDefaultOptions));
  // },
  // getChartPieSeriesDefaultOptions: function () {
  //   return JSON.parse(JSON.stringify(this.chartPieSeriesDefaultOptions));
  // },
  // getChartBarDefaultOptions: function () {
  //   return JSON.parse(JSON.stringify(this.chartBarDefaultOptions));
  // },
  // getChartBarSeriesDefaultOptions: function () {
  //   return JSON.parse(JSON.stringify(this.chartBarSeriesDefaultOptions));
  // },
  LitepieDatepickerDateFormat: "DD MMM YYYY",
  LitepieDatepickerMonthFormat: "MMM",
  backEndDateFormat: "",
  RESPONSE_MESSAGES: {
    EXPIRED_ACCESS_TOKEN: 'Expired Access Token',
    EXPIRED_REFRESH_TOKEN: 'Expired Refresh Token'
  },
  PERMISSION_STATES: {
    INCLUDED: 'Included',
    EXCLUDED: 'Excluded',
    FORBIDDEN: 'Forbidden'
  },
  NOTIFICATION_TYPES: {
    SHARED_DOCUMENT: 'shared-document',
    FOLLOW: 'follow',
    CONTACT: 'contact'
  }
  // EVENTS: {
  //   USER_PERMISSIONS_UPDATED: 'user-permissions-updated',
  //   USER_PERMISSIONS_SAVED: 'user-permissions-saved',
  //   USER_GROUPS_UPDATED: 'user-groups-updated',
  //   USER_GROUPS_SAVED: 'user-groups-saved',
  //   GROUP_USERS_UPDATED: 'group-users-updated',
  //   GROUP_USERS_SAVED: 'group-users-saved',
  //   GROUP_PERMISSIONS_UPDATED: 'group-permissions-updated',
  //   GROUP_PERMISSIONS_SAVED: 'group-permissions-saved',
  //   PERMISSION_USERS_UPDATED: 'permission-users-updated',
  //   PERMISSION_USERS_SAVED: 'permission-users-saved',
  //   PERMISSION_GROUPS_UPDATED: 'permission-groups-updated',
  //   PERMISSION_GROUPS_SAVED: 'permission-groups-saved',
  //   PASSWORD_SCORE_UPDATED: 'password-score-updated',
  //   UPDATING_PASSWORD_SCORE: 'updating-password-score',

  //   DATA_REQUESTED: 'data-requested',
  //   DATA_READY: 'data-ready',
  //   CLEAR_REQUESTED: 'clear-requested',

  //   OPEN_CHAT: 'open-chat',
  //   CLOSE_CHAT: 'close-chat',
  //   OPEN_CHAT_CREATE: 'open-chat-create',
  //   CLOSE_CHAT_CREATE: 'close-chat-create',

  //   MARK_CONVERSATION_AS_READ: 'mark-conversation-as-read',
  //   MARK_CONVERSATION_AS_UNREAD: 'mark-conversation-as-unread',

  //   MARK_NOTIFICATION_AS_READ: 'mark-notification-as-read',
  //   MARK_NOTIFICATION_AS_UNREAD: 'mark-notification-as-unread'
  // }

};

export { Config };
